import { graphql } from 'gatsby';
import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import Logo from '../assets/svg/logo.svg';

import { wait } from '../utils';

const transition = css`transition: all 500ms cubic-bezier(0.33, 1, 0.68, 1);`;
const visibilityProperties = ({ visible }) => visible
	? css`opacity: 1; ${transition}`
	: css`
		opacity: 0;
		transform: translateY(20px);
		${transition}
	`;

const LogoAndTitle = styled.div<{ visible: boolean; }>`
	margin-bottom: 1rem;
	${visibilityProperties}
`;
const Title = styled.h1`
	font-family: CallunaSemibold;
	font-size: 3.6rem;
	margin: 3rem 0 1rem 0;
`;
const SubTitle = styled.h2<{ visible: boolean; }>`
	font-family: MerriweatherLightItalic;
	font-size: 1.8rem;
	line-height: 2.6rem;
	color: var(--clrDimmed);
	margin-bottom: 5.6rem;
	${visibilityProperties}
`;
const Link = styled.a<{ visible: boolean; }>`
	font-family: NunitoSansExtraBold;
	text-transform: uppercase;
	color: var(--clrBody);
	text-decoration: none;
	display: inline-block;
	position: relative;
	${visibilityProperties}
	
	&::after {
		content: "";
		width: calc(100% + 0.4rem);
		height: 0.4rem;
		position: absolute;
		left: -0.2rem;
		bottom: -0.4rem;
		background: var(--clrBrand);
		opacity: 0.5;
		z-index: -1;
		transition: all 100ms ease-out;
	}
	&:hover::after {
		height: 0.8rem;
		bottom: 0.1rem;
	}
`;
const BrMobile = styled.br`
	display: none;
	@media only screen and (max-width: 420px) {
        display: inline;
    }
`;

const getInTouch = () => {
	const k = "ifmmpAebwjelsjkhtnbo/dpn"; // https://www.wmtips.com/tools/html-obfuscator
	let m = "";
	for (let i=0; i < k.length; i++) m += String.fromCharCode(k.charCodeAt(i)-1);
	window.location.href = `mailto:${m}`;
}

const IndexPage = ({ data }) => {
	const { contentfulDavidKrijgsman: content } = data;

	const [revealLogoAndTitle, setRevealLogoAndTitle] = useState<boolean>(false);
	const [revealSubtitle, setRevealSubtitle] = useState<boolean>(false);
	const [revealContactLink, setRevealContactLink] = useState<boolean>(false);

	useEffect(() => {
		(async function animate() {
			await wait(500);
			setRevealLogoAndTitle(true);
			await wait(500);
			setRevealSubtitle(true);
			await wait(1000);
			setRevealContactLink(true);
		})();
	}, []);

	return (
		<main>
			<LogoAndTitle visible={revealLogoAndTitle}>
				<Logo />
				<Title>{content.title}</Title>
			</LogoAndTitle>

			<SubTitle visible={revealSubtitle}>
				{content.subtitle
					.split('[br]')
					.map(part => <>{part}<BrMobile /></>)
				}
			</SubTitle>
			
			<Link visible={revealContactLink} onClick={() => getInTouch()} href="#">{content.contactLinkText}</Link>
			
		</main>
	)
}

export default IndexPage;

export const query = graphql`
	query {
		contentfulDavidKrijgsman(contentful_id: { eq: "3rNKKnUyVsrJBJ3ybqyxbc" }) {
			title
			subtitle
			contactLinkText
		}
	}
`;

